<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">Calculators</h2></template>
      </card-title>
    </template>
    <template #content-full>
      <h3>INTRODUCTION</h3>
      <div class="clarity-margin-bottom-l" style="width:100%;">
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center justify-content-center">
            <p>Helping you 'do the maths' to achieve your financial goals, model different scenarios, and see the potential impact of small changes to your plans.</p>
          </div>
          <div class="flex align-items-center justify-content-center" style="width: 280px;">
            <Button @click="loadVideoTour" label="Watch the Video Tour" class="clarity-gradient-grey clarity-btn" style="width: 276px;" />
          </div>
        </div>
      </div>

        <!--
      <div class="grid clarity-margin-bottom-l">
        <div class="col">
          <div class="img-wrapper flex mr-5 mt-5 mb-5 justify-content-center align-items-center">
            <img class="vector-img" src="../../assets/img/calculators/Pension-Calculator-Vector.png">
          </div>
        </div>
        <div class="col">
          <div class="flex">
            <div class="flex align-content-center" style="height:400px;">
              <div class="flex flex-column justify-content-center">
                <h3>PENSION CALCULATOR</h3>
                <p>The pension calculator will help you to project your future pension income based on existing savings and current pension fund values. If you have a pension shortfall the calculator will help you to work out the additional savings required to meet your target income.</p>
                <Button label="Start The Calculator" class="mr-2 mb-2 clarity-gradient-orange clarity-btn" style="width: 256px;" />
              </div>
            </div>
          </div>
        </div>
      </div>
      -->

      <div class="grid clarity-margin-bottom-l">
        <div class="col">
          <div class="flex">
            <div class="flex align-content-center" style="height:400px;">
              <div class="flex flex-column justify-content-center">
                <h3>INVESTMENT CALCULATOR</h3>
                <p>The investment calculator covers two topics, firstly calculating the projected fund balance at the end of a savings term. Alternatively you can calculate the savings required to meet a specific fund value at the end of your savings term. <!--Best used in conjunction with the budgeting calculator. If you are considering giving investments away as a gift we also suggest you review the inheritance tax calculator.--></p>
                <router-link to="/tools/investmentcalculator"><Button label="Start The Calculator" class="mr-2 mb-2 clarity-gradient-orange clarity-btn" style="width: 256px;" /></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="img-wrapper light flex mr-5 mt-5 mb-5 justify-content-center align-items-center">
            <img class="vector-img" src="../../assets/img/calculators/Investment-Calculator-Vector.png">
          </div>
        </div>
      </div>

      <div class="grid clarity-margin-bottom-l">
        <div class="col">
          <div class="img-wrapper flex mr-5 mt-5 mb-5 justify-content-center align-items-center">
            <img class="vector-img" src="../../assets/img/calculators/Education-Fees-Calculator-Vector.png">
          </div>
        </div>
        <div class="col">
          <div class="flex">
            <div class="flex align-content-center" style="height:400px;">
              <div class="flex flex-column justify-content-center">
                <h3>EDUCATION FEES CALCULATOR</h3>
                <p>Our education fees calculator allows you to calculate how much you would need to save, over what period in order to cover your child or children’s education costs. This could apply to private school education, college or university.</p>
                <router-link to="/tools/educationcalculator"><Button label="Start The Calculator" class="mr-2 mb-2 clarity-gradient-orange clarity-btn" style="width: 256px;" /></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

        <!--
      <div class="grid clarity-margin-bottom-l">
        <div class="col">
          <div class="flex">
            <div class="flex align-content-center" style="height:400px;">
              <div class="flex flex-column justify-content-center">
                <h3>INHERITANCE TAX (IHT) CALCULATOR</h3>
                <p>The inheritance tax calculator should be used as a guide to help you understand what your inheritance tax exposure could be today. Based on the inputted data it will calculate your assets against your liabilities, it will take any pertinent personal information into account and will give you a guide to what any inheritance tax exposure may be.</p>
                <Button label="Start The Calculator" class="mr-2 mb-2 clarity-gradient-orange clarity-btn" style="width: 256px;" />
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="img-wrapper light flex mr-5 mt-5 mb-5 justify-content-center align-items-center">
            <img class="vector-img" src="../../assets/img/calculators/Inheritance-Tax-Calculator-Vector.png">
          </div>
        </div>
      </div>
      -->
      <div class="grid">
        <div class="col">
          <div class="flex">
              <div class="flex align-content-center" style="height:400px;">
                  <div class="flex flex-column justify-content-center">
                      <h3>MORTGAGE PAYMENT CALCULATOR</h3>
                      <p>Thinking of buying a house or changing your fixed rate? Use our mortgage calculator to calculate your potential monthly repayments.</p>
                      <router-link to="/tools/mortgagecalculator"><Button label="Start The Calculator" class="mr-2 mb-2 clarity-gradient-orange clarity-btn" style="width: 256px;" /></router-link>
                  </div>
              </div>
          </div>
        </div>
        <div class="col">
          <div class="img-wrapper flex mr-5 mt-5 mb-5 justify-content-center align-items-center">
              <img class="vector-img" src="../../assets/img/calculators/Mortgage-Payment-Calculator-Vector.png">
          </div>
        </div>
      </div>
    </template>
  </content-card-full>

  <Modal v-if="showVideoTour" id="sm-welcome-tour">
    <template #body>
      <content-card-full>
        <template #title>
          <div class="flex justify-content-between title align-items-center">
            <h2>Calculators Guide</h2>
            <Button class="clarity-close-modal-btn" @click="closeModal"><span
                class="material-icons-round grey">close</span></Button>
          </div>
        </template>
        <template #content-full>
          <div>
            <div style='padding:56.25% 0 0 0;position:relative;'>
              <iframe src='https://vimeo.com/showcase/10709708/embed' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
            </div>
          </div>
        </template>
      </content-card-full>
    </template>
  </Modal>

</template>

<script>
import CardTitle from '@/components/common/CardTitle.vue';
import ContentCardFull from '@/components/common/ContentCardFull.vue';
import Modal from '@/components/common/Modal';
import { ref } from 'vue';

export default {
  name: "TabCalculators",
  setup() {
    const showVideoTour = ref(false);

    const loadVideoTour = () => {
      //console.log('load tour');
      if(showVideoTour.value) {
        showVideoTour.value = false;
      } else {
        showVideoTour.value = true;
      }
    }

    const closeModal = () => {
      if(showVideoTour.value) {
        showVideoTour.value = false;
      }
    }

    return {
      loadVideoTour,
      closeModal,
      showVideoTour
    }
  },
  components: {
    CardTitle,
    ContentCardFull,
    Modal
  }
}
</script>

<style scoped>
  .vector-img {
    max-height: 75%;
    max-width: 70%;
  }
  .img-wrapper {
    /* UI Properties */
    height: 360px;
    border: 2px solid var(--clarity-pure-white);
    border-radius: 27px;
    opacity: 1;
    box-shadow: 0px 5px 20px #0000000D;
    background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
    /*background-image: url('../../assets/img/dashboard/Background.png');
    background-size: cover;*/
  }
  .img-wrapper.light {
    box-shadow: 0px 5px 20px #0000000D;
    background: transparent linear-gradient(180deg, #7CC2E2 0%, var(--clarity-light-blue) 100%) 0% 0% no-repeat padding-box;
  }

  /* MODAL CSS */
  #sm-welcome-tour {
    /* needs to be high to sit above all other elements, including nav*/
    z-index: 3104;
  }

  #sm-welcome-tour:deep(.modal-body) {
    min-width: 65%;
  }

  #sm-welcome-tour:deep(.modal-close) {
    display: none !important;
  }

  #sm-welcome-tour:deep(.clarity-card-content) {
    width: unset !important;
  }

  #sm-welcome-tour:deep(.content-title) {
    padding: 30px;
  }
  #sm-welcome-tour:deep(.content-full) {
    padding: 0px 30px 30px 30px;
  }
</style>
